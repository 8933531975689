
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import QuotesService from "@/core/services/car/QuotesService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import LeadDriverDetailsView from "@/components/partials/leaddriverdetails/Lead-DriverDetiails-View.vue";
import { getCurrentDate, roles } from "@/store/stateless/store";

interface AddQuoteBodyParam {
  customer_id: any;
  car_year: string | null;
  manufacturer_id: number | null;
  model_id: number | null;
  trim_level_id: number | null;
  is_new: number | null;
  car_value: number | null;
  first_registration_date: string | null;
  registration_emirate: any | null;
  dob: string | null;
  nationality: number | null;
  first_driving_license_country: number | null;
  driving_experience: number | null;
  uae_driving_experience: number | null;
  policy_start_date: string;
  claimed_insurance: number;
  no_claim_certificate: any | null;
  claims: string;
  gcc_specification: number | null;
  personal_use: number | null;
  current_policy_active: number | null;
  fully_comprehensive: number | null;
  third_party_liability: number | null;
  existing_policy_expired: number | null;
  driver_name: string;
  policy_type: any;
  gender:any|null;
  policy_data: Array<PolicyData>;
}

interface PolicyData {
  companyId: any;
  repair: any;
  value: any;
  discount: any;
}
export default defineComponent({
  name: "add-driver-details",
  props: {},
  components: { LeadDriverDetailsView },
  data() {
    return {
      dob: "",
      policyBegin: "",
      registrationDate: "",
    };
  },
  computed: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    let leadDriverDetailsObj = ref<AddQuoteBodyParam>({
      customer_id: route.params.customerId,
      car_year: "",
      manufacturer_id: null,
      model_id: null,
      trim_level_id: null,
      is_new: 2,
      car_value: 0,
      first_registration_date: '',
      registration_emirate: 7,
      dob: null,
      nationality: null,
      first_driving_license_country: null,
      driving_experience: 4,
      uae_driving_experience: 4,
      policy_start_date: getCurrentDate.today(),
      claimed_insurance: 2,
      no_claim_certificate: "1",
      claims: "",
      gcc_specification: 1,
      personal_use: 1,
      current_policy_active: 1,
      fully_comprehensive: 1,
      third_party_liability: 2,
      existing_policy_expired: 2,
      driver_name: "",
      policy_type: 0,
      gender:null,
      policy_data: [],
    });

    onMounted(() => {
      if(!roles.customerdetails.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Generate Quote PDF", ["Leads & Tasks"]);
    });

    let getIsManualQuoteLoading = computed(() => {
      return store.getters.getManaulOrderLoading;
    });

    return {
      getIsManualQuoteLoading,
      roles,
      leadDriverDetailsObj,
    };
  },
});
